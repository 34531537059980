<template>
  <div>
    <TopNav />

    <div class="container">
      <div class="col text-center text-light">
        <div class="row mt-4 mb-4">
          <div class="col">
            <h1>Rekam Video Langsung</h1>
          </div>
        </div>

        <div v-if="!videoUrl">
          <div class="row">
            <div class="col">
              <video ref="preview" :srcObject="stream" autoplay></video>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <button @click="startRecording" :disabled="recording">
                Mulai Merekam
              </button>
              <button @click="stopRecording" :disabled="!recording">
                Stop Recording
              </button>
            </div>
          </div>
        </div>

        <div v-if="videoUrl">
          <div class="row">
            <div class="col">
              <video controls :src="videoUrl"></video>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <button @click="rekamUlangClicked">Rekam Ulang</button>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <button class="btn btn-gradient" @click="submitForm">
                Kirim hasil rekaman
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";

export default {
  components: {
    TopNav,
  },
  data() {
    return {
      stream: null,
      mediaRecorder: null,
      videoChunks: [],
      videoUrl: null,
      recording: false,

      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;
    } else {
      self.$router.push("/");
    }

    // checking KYC step
    await self.kycChecing();
  },

  methods: {
    async kycChecing() {
      var self = this;
      var res = await thestore.postKycCheckingStep(self.token);
      if (res.kyc_step != 2) {
        if(res.kyc_type == "Indonesia") {
          if (res.kyc_step == 0) {
            // selesai nol
            self.$router.push("/kyc/id/satu");
          } else if (res.kyc_step == 1) {
            // selesai 1
            self.$router.push("/kyc/id/dua");
          } else if (res.kyc_step == 3) {
            // selesai 3
            self.$router.push("/kyc/id/empat");
          }
        }
        else {
          if (res.kyc_step == 0) {
            // selesai nol
            self.$router.push("/kyc/global/satu");
          } else if (res.kyc_step == 1) {
            // selesai 1
            self.$router.push("/kyc/global/dua");
          } else if (res.kyc_step == 3) {
            // selesai 3
            self.$router.push("/kyc/global/empat");
          }
        }
      }
    },

    async startRecording() {
      var self = this;
      try {
        self.recording = true;
        self.stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        self.$refs.preview.srcObject = self.stream;

        console.log("sa");
        self.mediaRecorder = new MediaRecorder(self.stream);

        self.mediaRecorder.addEventListener("dataavailable", (event) => {
          self.videoChunks.push(event.data);
        });

        self.mediaRecorder.start();
      } catch (error) {
        console.log("error lah");
        console.error(error);
      }
    },
    stopRecording() {
      var self = this;

      if (self.mediaRecorder) {
        self.recording = false;
        self.mediaRecorder.stop();
        self.stream.getTracks().forEach((track) => track.stop());

        console.log("self.videoChunks", self.videoChunks);

        setTimeout(function () {
          let videoBlob = new Blob(self.videoChunks, { type: "video/webm" });
          self.videoUrl = URL.createObjectURL(videoBlob);
          self.videoUrl = webkitURL.createObjectURL(videoBlob);
        }, 500);
      }
    },

    async submitForm() {
      var self = this;

      var formData = new FormData();
      formData.append("orang_video_file", await this.getVideoFile());

      var res = await thestore.postKycGlobalVideoUpload(self.token, formData);
      console.log("res", res);

      if (res.success == true) {
        self.$router.push("/kyc/id/empat");
      } else {
        alert(res.msg);
      }
    },

    async getVideoFile() {
      let response = await fetch(this.videoUrl);
      let blob = await response.blob();
      return new File([blob], "recording.webm", { type: "video/webm" });
    },

    rekamUlangClicked() {
      var self = this;
      self.$router.go();
    },
  },
};
</script>
