<template>
  <div>
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9 text-light">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group file">
                  <label>File</label>
                  <div>
                    <input
                      type="file"
                      class="form-control"
                      placeholder="Bukti Transfer"
                      @change="handleFileUpload($event)"
                    />
                  </div>
                  <span class="caution"
                    >Hanya tipe gambar: jpg, jpeg, png, bmp | Max size:
                    1MB</span
                  >
                  <span class="caution"
                    >Gambar harus jelas dan minimum resolusi 100px x 100px</span
                  >
                </div>

                <div class="col-md-12 mt-2">
                  <button type="submit" class="btn btn-light">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
// import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
// import HeadBalance from "@/components/Settings/Wallets/HeadBalance.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    // HeadBalance,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      depoidr_id: this.$route.query.id,
      depoidr_file: "",
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    } else {
      self.$router.push("/");
    }
  },

  async mounted() {
    var self = this;

    console.log("self.depoidr_id", self.depoidr_id);
  },

  methods: {
    handleFileUpload(event) {
      this.depoidr_file = event.target.files[0];
    },

    async submitForm() {
      var self = this;

      var formData = new FormData();
      formData.append("depoidr_id", self.depoidr_id);
      formData.append("depoidr_file", self.depoidr_file); // file

      console.log("formData", formData);

      var res = await thestore.postWalletsIdrDepositFile(self.token, formData);
      console.log("res", res);

      if (res.success == true) {
        self.$router.push("/settings/wallets/idr_deposit");
      } else {
        alert(res.msg);
      }
    },
  },
};
</script>
