<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Deposit Address</h5>

      <div
        class="wallet-address"
        v-if="ethereumAddress != '' && ethereumAddress != null"
      >
        <div class="card">
          <div class="card-body">
            <div class="row gap-5">
              <div class="col-md-8">
                <p class="text-light">
                  Deposit ke alamat ini tidak terbatas. Perhatikan bahwa Anda
                  mungkin tidak dapat withdraw semua dana Anda sekaligus jika
                  Anda deposit lebih dari batas withdrawal harian Anda.
                </p>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :value="`${ethereumAddress}`"
                    :id="`input_address_${currency}`"
                  />
                  <!-- <div class="input-group-prepend">
                    <button
                      class="btn btn-gradient"
                      @click="copyAddressToClipboard"
                    >
                      SALIN
                    </button>
                  </div> -->
                </div>
              </div>
              <div
                class="col-md-4 d-flex justify-content-center justify-content-md-end mt-4 mt-md-0"
              >
                <qrcode-vue :value="ethereumAddress" :size="200" level="H" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-outline-orange" v-else @click="requestAnAddress">
        Minta Address
      </button>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import QrcodeVue from "qrcode.vue";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {
    QrcodeVue,
  },
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      ethereumAddress: "",
    };
  },

  async mounted() {
    var self = this;

    // Dapatkan wallet address dari API
    await self.getEthereumWalletAddress();
  },

  methods: {
    async getEthereumWalletAddress() {
      var self = this;
      var addressObj = await thestore.getEthereumWalletAddress(self.token);
      if (addressObj.success == true) {
        self.ethereumAddress = addressObj.data.wallet_address;
      }
    },

    async requestAnAddress() {
      var self = this;

      var res = await thestore.postRequestEthereumAddress(self.token);
      if (res.success == true) {
        self.ethereumAddress = res.data.wallet_address;
      } else {
        alert(res.msg);
      }
    },

    /**
     * TODO: BELUM BERHASIL COPY WALAUPUN SUDAH HTTPS
     * CONSIDERATION:
     * Hanya bekerja pada halaman yang support https
     * Jadi ini tes nya pada production
     * DAN INI SUDAH TESTED. JIKA DIKEMUDIAN HARI ADA PROBLEM, HARAP TEST PADA PRODUCTION (HTTPS)
     */
    copyAddressToClipboard() {
      var self = this;

      var copyText = document.getElementById(`input_address_${self.currency}`);
      copyText.value = self.ethereumAddress;

      console.log("copyText", copyText);

      // // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);

      // Alert the copied text
      alert("Copied the text: " + copyText.value);
    },
  },
};
</script>
